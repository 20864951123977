module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-9bb084d118/0/cache/gatsby-plugin-manifest-npm-5.7.0-ef15e52c51-5c3a69fb9c.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ANSI Global","short_name":"ANSI","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0eae4ebf9b5787c9a5de77371e635ee3"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-nprogress-virtual-7e023dc438/0/cache/gatsby-plugin-nprogress-npm-5.7.0-a34af10505-a2c9740a34.zip/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-f1a7b0c301/0/cache/gatsby-plugin-google-gtag-npm-5.7.0-8c5b3622df-ba92c37c20.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YL59MCGMDT"]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-868d04d1f7/0/cache/gatsby-plugin-offline-npm-6.7.0-a67b95cdfc-35cc679017.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-92a80fd4dd/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
