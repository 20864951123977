// mdbreact
import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap-css-only/css/bootstrap.min.css"
import "mdbreact/dist/css/mdb.css"

import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const recaptchaKey="6LeW2XYfAAAAAKhyTcjPhxUwPS3Xmo8QogWtkqt6"

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
    {element}
  </GoogleReCaptchaProvider>
)
